import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

import awsExports from '../../../aws-exports';
import { AwsUser, User } from '../models/auth.models';
import { UserProfileService } from './user.service';



@Injectable({ providedIn: 'root' })
export class AuthenticationService {


    constructor (private authenticatorService: AuthenticatorService) {
      //  Amplify.configure(awsExports);
    }

    public currentUser() : AwsUser {

       const user = this.authenticatorService.user;
            return user ? { username: user.username, email: user.attributes?.email} as AwsUser : null;
    }

    public async getToken() {
      return (await Auth.currentSession()).getAccessToken().getJwtToken();
    }

    // public isLoggedIn()  : boolean {


    //    return Auth.currentAuthenticatedUser()
    //             .then((user) => {
    //                 if (!user) {
    //               //  this.router.navigate(['/login']);
    //                 return false;
    //                 }
    //                 return true;

    //             })
    //             .catch((err) => {
    //             //this.router.navigate(['/login']);
    //             return false;
    //             });
       
    // }
    
    public logout(): void {

        this.authenticatorService.signOut();
    }
}

