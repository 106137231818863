// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:d3ecbec5-1c8d-45df-8c03-21ea2944c1e3",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_XVAPC6kH3",
    "aws_user_pools_web_client_id": "44e2c0b4fc06rugtt7klu7v291",
    "oauth": {}
};


export default awsmobile;
